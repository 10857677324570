import VideoSrc from '../Video/Atelier-juin_Footage_FINAL.mp4'
import React, {useRef, useEffect} from "react"

export default function VideoSection() {
    return (
        <div>
            <div className='whiteSection'>
                <div className='secondLine'>** ATELIER GRATUIT **</div>

                <div>
                    Révolutionnez votre approche pédagogique :
                </div>
                <div className='whiteSectionBottom'>
                    intégrez la réalité augmentée à vos formations en présentiel
                </div>
            </div>
            <div className='videoContainer'>
                {/* <video
                    autoPlay
                    loop
                    muted
                    style={{
                        // positioin: 'absolute',
                        left: '50%',
                        top: '50%',
                        width: '100%',
                        objectFit: 'cover',
                        zIndex: '-1',
                        // transform: 'translate(-50%, -50%)',
                        // height: '100%',
                    }}
                >
                    <source src={VideoSrc} type='video/mp4' />
                </video> */}

                {/* <div dangerouslySetInnerHTML={{
                    __html: `<video autoplay loop muted style="width:100%;">
                    <source src=${VideoSrc} type="video/mp4" />
                    Your browser does not support the video tag.
                    </video>`,
                }} /> */}

                {/* <div dangerouslySetInnerHTML={{
                    __html: `<video autoplay loop muted style="width: 100%; height: auto; object-fit: cover; z-index: 0;">
                    <source src=${VideoSrc} type="video/mp4" />
                    Your browser does not support the video tag.
                    </video>`,
                }} /> */}

                <AutoPlaySilentVideo video={VideoSrc} />

            </div>
        </div>
    )

    function AutoPlaySilentVideo(props) {
        const videoRef = useRef(undefined);
        useEffect(() => {
            videoRef.current.defaultMuted = true;
        })
        return (
            <video
                ref={videoRef}
                loop
                autoPlay
                muted
                playsInline
                style={{width: '100%'}}>
                <source src={props.video} type="video/mp4" />
            </video>
        );
    }
}