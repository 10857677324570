import './App.css';
import Landing from './components/Landing';

export default function App() {
  return (
    <>
      <Landing />
    </>
  );
}
