
export default function BottomSection() {
    return (
        <>
            <div className='textOfBottomSection'>
                <h3 className='bottomFirstLine'>Date et heure</h3>
                <div>Le jeudi 8 juin 2023 de 13h30 à 15h30</div>

                <div className='hourSection'>
                    <h3>Endroit</h3>
                    <div>Bureaux de ALGEGO</div>
                    <div>3970, rue Saint-Ambroise</div>
                    <div>Montréal H4C 2C7</div>
                    <div><a href='https://goo.gl/maps/5wcreragomfjfdXR7' target='_blank'>Lien Google Maps</a></div>
                </div>
                <div className='hourSectionQuery'>
                    <h3>Horaire de l'atelier</h3>
                    <div>8h00 à 8h15</div><div className='itemQuery'>Accueil</div>
                    <div>8h15 à 9h30</div><div className='itemQuery'>Mise en contexte et scénarisation</div>
                    <div>9h30 à 10h00</div><div className='itemQuery'>Visite du studio vert</div>
                    <div>10h00 à 12h00</div><div className='itemQuery'>Tournage en <em>motion capture</em></div>
                </div>

                <h3>Information</h3>
                <div>Julie Guillaume</div>
                <div>Directrice du développement</div>
                <div>et des solutions innovantes</div>
                <div><a href='mailto:julieguillaume@algego.com'>julieguillaume@algego.com</a></div>
            </div>
        </>
    )
}