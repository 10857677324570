
export default function MiddleSection() {
    return (
        <>
            <div className='middleTxt'>Nous vous invitons à un atelier immersif placé sous le signe de l’innovation dans la formation professionnelle.
            </div>
            <div className='middleTxt middleTxt-2'>Vivez l’expérience d’une formation mixte combinant mode
                présentiel et réalité augmentée (RA), et explorez avec d’autres professionnels les possibilités infinies de cette technologie qui permet de :</div>
                <div className="middleTxtList">
                    <ul>
                        <li>solliciter la collaboration et la créativité des apprenants avec  des activités interactives</li>
                        <li>optimiser l’engagement des apprenants via des expériences immersives</li>
                        <li>faciliter la démonstration et la compréhension de concepts ou de procédés complexes</li>
                        <li>favoriser l’apprentissage autonome</li>
                        <li>renforcer la rétention des nouvelles connaissances et consolider les compétences acquises</li>
                    </ul>
                </div>
        </>
    )
}