import Logo from '../images/logo-noir.png'
import ContactForm from './ContactForm'
import FooterLogo from '../images/circle-algego.png'
import MiddleSection from './MiddleSection'
import BottomSection from './BottomSection'
import VideoSection from './VideoSection'

export default function Landing() {

    const Algego = 'https://algego.com'
    return (
        <>
            <div className='logo'>
                <div className='logoInner'><a href={Algego} target='_blank'><img src={Logo} alt='logo' /></a></div>
            </div>

            <div className='firstSection'>
                <div className='firstLine'>
                    <div className='firstLinePremier'>Formation + réalité augmentée (RA) =
                    <div>un univers de possibilités</div>
                    </div>
                    <div className='firstLineQuery'>Formation + réalité augmentée (RA) =
                    <div>un univers de possibilités</div>
                    </div>
                </div>
                {/* <div className='secondLine'>** ATELIER GRATUIT **</div> */}
            </div>

            <div>
                <VideoSection />
            </div>

            <div className='secondSection'>
                <div className='secondFirst'>Le jeudi 8 juin 2023 de 13h30 à 15h30</div>
                <div className='secondSecond'>Places limitées :<br />RSVP avant le lundi 5 juin à 17h</div>
            </div>

            <div className='container-fluid'>
                <div className='mainSection'>
                    <div className='row mainFirst'>
                        <div className='col-md-2 mainLeft'>
                        </div>
                        <div className='col-md-8 mainMiddle'>
                            <MiddleSection />
                        </div>
                        <div className='col-md-2 mainRight'>
                        </div>
                    </div>
                    <div className='row mainQuery'>
                        <div className='col-12 mainMiddle'>
                            <MiddleSection />
                        </div>
                    </div>
                </div>
                <div className='bottomSection'>
                    <div className='row bottomFirst'>
                        <div className='col-md-2'></div>
                        <div className='col-md-4'>
                            <BottomSection />
                        </div>
                        <div className='col-md-4'>
                            <ContactForm />
                        </div>
                        <div className='col-md-2'></div>
                    </div>
                    <div className='row bottomQuery'>
                        <div className='col-12'>
                            <BottomSection />
                        </div>
                        <div className='col-12'>
                            <ContactForm />
                        </div>
                    </div>
                </div>
                <footer>
                    <div className='footerTxt'><h3>De l'idée à la diffusion, ALGEGO a la solution.</h3></div>
                    <div className='footerTxtQuery'><h3><div>De l'idée à la diffusion</div>ALGEGO a la solution.</h3></div>
                    <div><a href={Algego} target='_blank'><img src={FooterLogo} alt='logo' /></a></div>
                </footer>
            </div>
        </>
    )
}